@import 'src/assets/styles/variables';

.project-gallery-container {
  display: grid;
  //grid-template-columns: repeat(5, auto);
  grid-template-columns: repeat(auto-fill, 220px);
  grid-gap: 20px;
  margin: 50px 0 40px;
  align-content: center;
  justify-content: center;
}

.gallery-image {
  width: 100%;
  height: 100%;
  border: solid 1px $white;
}