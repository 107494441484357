@import 'src/assets/styles/variables';

.about-container {
  background-image: url('../../assets/images/about.png');
  background-size: cover;
  background-position: 80% center;
  flex-direction: row;
  justify-content: start;
  display: block;
}

.about-section__text p{
  margin: 10px 0;
  //font-weight: 450;
}

.about-section__text {
  background: rgba( $white, .85);
  backdrop-filter: blur(23pxs);
  padding: 1rem;
  border: 1px solid rgba( $white, .23);
  border-radius: 5px;
}

.about-section__mini-info-banner {
  position: relative;
  display: flex;
  justify-content: flex-end;
  gap: 25px;
  color: $white;
  z-index: 0;
  padding-right: 10px;
  margin-top: 5px;
}

.about-section__mini-info-banner:after {
  content: "";
  position: absolute;
  right: 0;
  width: 200%;
  height: 100%;
  background-color: $darkGrey;
  box-shadow: rgb(34,33,33, 0.5) 0 0 10px 1px;
  z-index: -1;
  border-radius: 5px;
}

.about-section__mini-info-content {
  padding: 2rem 1rem 1rem;
  text-align: center;
}

@media (max-width: 576px) {
  .about-section__mini-info-content {
    padding: 1rem 0.5rem 0.5rem;
  }
}

.about-section__mini-info-content p {
  font-size: 0.9rem;
  margin-top: 10px;
}

/* Icons */
.about-icon {
  font-size: 2.1rem;
  color: $white;
}