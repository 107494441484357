@import 'src/assets/styles/variables';

.contact-info {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.contact-title {
  padding-top: 3rem;
  font-size: 3rem;
  text-align: center;
  padding-bottom: 3rem;
}

.contact-info-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 50px 50px;
}

@media (max-width: 767px) {
  .contact-info-container {
    flex-direction: column;
  }
  .contact-info {
    padding-bottom: 20px;
  }
}

.contact-icon {
  font-size: 3rem;
  color: $black;
  padding: 20px;
}