@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;1,300;1,400&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,300;1,400;1,500;1,700&display=swap');

// fonts
$bodyFont: 'Roboto', sans-serif;
$titleFont: 'Lato', sans-serif;

// colors
$darkGrey: #222121;
$white: #F1F1F1;
$lightGrey: #C0C0C0;
$black: #070707;


