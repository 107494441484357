@import 'src/assets/styles/variables';

.project-pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination-dot {
  padding: 5px;
  font-size: 1rem;
}

.pagination-dot--active {
  font-size: 0.2rem;
  color: $darkGrey;
  border: 5px solid $white;
  border-radius: 100%;
  margin: 0 3px;
}
