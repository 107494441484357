@import 'src/assets/styles/variables';

.footer-container {
  min-height: 15vh;
  color: $white;
  flex-direction: column;
  text-align: center;
}

.footer-logo-container {
  height: 120px;
  width: 120px;
  margin: 10px;
}

.footer-logo {
  height: auto;
  width: auto;
}