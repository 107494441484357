@import 'variables';
@import 'mixins';

.App {
  display: flex;
  flex-direction: column;
  justify-items: center;

  & section {
    display: inline-flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 50px 0;
  }
}

body {
  background-color: $darkGrey;
  font-family: $bodyFont;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

h2 {
  font-family: $titleFont;
  font-weight: 600;
}

.landing-container, .projects-container {
  @include content-section(90vh, $darkGrey, $white);
}

.about-container, .contact-container {
  @include content-section(50vh, $white, $black);
  position: relative;
}
