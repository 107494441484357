@import 'src/assets/styles/variables';

.landing-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 80px;
}

.landing-left-container {
  position: absolute;
  display: flex;
  flex-wrap: nowrap;
  top: 20%;
  max-height: 50%;
  padding-left: 70px;
  min-width: 50%;
}

.image-container-one {
  width: 18%;
  //width: 17rem;
  //height: 27rem;
  border: solid 1px $white;
}

.image-container-two {
  width: 18%;
  //width: 17rem;
  //height: 27rem;
  border: solid 1px $white;
  transform: translate(-45%, 30%);
}

.landing-right-container {
  background: rgba( $darkGrey, .7);
  backdrop-filter: blur(13pxs);
  padding: 2rem;
  border: 1px solid rgba( $darkGrey, .23);
  border-radius: 5px;
}

.btn {
  font-family: $titleFont;
  padding: 10px 20px;
  margin: 0.5rem 0;
  text-transform: uppercase;
  cursor: pointer;
}

@media (max-width: 1200px) {
  .landing-left-container {
    min-width: 50%;
    padding-left: 50px;
  }
  .image-container-one {
    width: 20% !important;
  }
  .image-container-two {
    width: 20% !important;
  }
}

@media (max-width: 992px) {
  .landing-left-container {
    padding-left: 10px;
  }
  .image-container-one {
    width: 25% !important;
  }
  .image-container-two {
    width: 25% !important;
  }
}

@media (max-width: 768px) {
  .landing-left-container {
    min-width: 70%;
    top: 25%;
  }
  .image-container-one {
    width: 40% !important;
  }
  .image-container-two {
    width: 40% !important;
  }
}

@media (max-width: 576px) {
  .landing-left-container {
    min-width: 90%;
  }
  .image-container-one {
    width: 50% !important;
  }
  .image-container-two {
    width: 50% !important;
  }
}