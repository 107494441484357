@import 'src/assets/styles/variables';

.nav-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  min-height: 80px;
  z-index: 1000;
  background-color: $black;
  display: flex;
  align-items: center;
  color: $white;
  font-family: $titleFont;
  padding: 0 10px;
}

.siteId {
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: flex-start;
}

.site-logo {
  cursor: pointer;
  height: 55px;
  width: 55px;
}

.siteNav {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.nav-links ol {
  list-style: none;
  display: inline-flex;
  padding: 0 10px;
  margin: 0;
}

.nav-links li {
  padding: 25px;
}

.nav-links a {
  text-decoration: none;
  cursor: pointer;
  color: $white;
}

.nav-links a::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  background-color: $white;
  transition: width 0.3s ease-out;
}

.nav-links a, .nav-links a:focus {
  color: $white;
  padding-bottom: 4px;
  display: inline-block;
  position: relative;
}

.nav-links a:hover::before, .nav-links a:focus {
  width: 100%;
}

/* mobile nav */
.mobile-nav-toggle {
  display: none;
}

@media screen and (max-width: 992px) {
  .nav-links {
    display: none;
  }
  .mobile-nav-toggle {
    display: flex;
    right: 20px;
    font-size: 30px;
  }
  #hidden {
    position: absolute;
    left: 0;
    right: 0;
    top: 80px;
    height: auto;
    width: 100%;
    background-color: $black;
    display: flex;
    flex-direction: column;
  }
  #hidden ol {
    flex-direction: column;
    text-align: center;
    padding: 0;
  }
  #hidden li {
    border-top: solid 1px $darkGrey;
  }
  //#hidden li:nth-child(1) {
  //  border: none;
  //}
}

@media screen and (max-width: 576px) {
  #hidden {
    width: 100%;
    left: 0;
    right: 0;
  }
}

